import type {
  MedicalTransporterType,
  MissionStatus,
  PassengerStatus,
  PhoneRavenStatus,
  PassengerIncidentType,
  PricingType,
  MedicalTransporterGovPricingType,
} from '@prisma/client'
import type {SubType} from '@ambler/shared'
import {dispatchStatuses} from '@ambler/shared'

export const transporterTypes: Record<MedicalTransporterType, string> = {
  DEFAULT: 'Transporteur',
  DISPATCHER: 'Dispatcher',
  AFFILIATE: 'Filiale',
}

export const passengerVerificationScopeOptions = {
  ALL: 'Tous les trajets',
  AGREEMENT: 'Trajets conventionnés',
  NONE: 'Aucun',
}

export const pricingTypeOptions: Record<PricingType, string> = {
  CPAM: 'Socle CPAM',
  CPAM_SEFI_GEO: 'CPAM majoré (SEFi et geoloc)',
  CUSTOM: 'Personnalisé',
}

export const mtGovPricingTypeOptions: Record<MedicalTransporterGovPricingType, string> = {
  CPAM: 'Socle CPAM',
  CPAM_SEFI_GEO: 'CPAM majoré (SEFi et geoloc)',
}

export const PassengerIncidentGroup: Record<string, PassengerIncidentType[]> = {
  minor: [
    'HPTL_FLAG_DELAY',
    'HPTL_FLAG_OUTBOUND_DELAY',
    'HPTL_FLAG_INBOUND_DELAY',
    'HPTL_FLAG_MT_MISCONDUCT',
    'TPTR_FLAG_DOCUMENTS_NOTREADY',
    'TPTR_FLAG_PTNT_NOTREADY',
    'TPTR_FLAG_PICKUP_TIME_CHANGE',
    'TPTR_FLAG_WAITING_FOR_DOCUMENTS',
    'TPTR_FLAG_ADD_COMMENT',
    'TPTR_FLAG_PATIENT_NOT_READY_FOR_DISCHARGE',
    'TPTR_FLAG_ORDER_MISSING_INFORMATION',
    'TPTR_FLAG_WAITING_FEE_REQUESTED',
  ],
  hptl: [
    'HPTL_FLAG_DELAY',
    'HPTL_FLAG_PTNT_MISS',
    'HPTL_FLAG_PTNT_LEFT',
    'HPTL_FLAG_ORDER_ERROR',
    'HPTL_FLAG_LATE_CANCEL',
    'HPTL_FLAG_AUTO_LATE_CANCEL',
    'HPTL_FLAG_OTHER',
    'HPTL_FLAG_NO_RETURN_RIDE',
    'HPTL_FLAG_OUTBOUND_DELAY',
    'HPTL_FLAG_INBOUND_DELAY',
    'HPTL_FLAG_MT_MISCONDUCT',
    'HPTL_FLAG_ORDER_INSTRUCTIONS_NOT_RESPECTED',
  ],
  tptr: [
    'TPTR_FLAG_ORDER_ERROR',
    'TPTR_FLAG_LATE_CANCEL',
    'TPTR_FLAG_NO_RETURN_RIDE',
    'TPTR_FLAG_PTNT_LEFT',
    'TPTR_FLAG_PTNT_UNCOMPAT',
    'TPTR_FLAG_PTNT_NOTREADY',
    'TPTR_FLAG_NO_RIDE_PTNT_NOTREADY',
    'TPTR_FLAG_RDV_MISS',
    'TPTR_FLAG_VEHICLE_UNAVAILABLE',
    'TPTR_FLAG_DOCUMENTS_NOTREADY',
    'TPTR_FLAG_OTHER',
    'TPTR_FLAG_DUPLICATED_ORDER',
    'TPTR_FLAG_PICKUP_TIME_CHANGE',
    'TPTR_FLAG_WAITING_FOR_DOCUMENTS',
    'TPTR_FLAG_ADD_COMMENT',
    'TPTR_FLAG_ADDRESS_OR_DATE_CHANGE',
    'TPTR_FLAG_VEHICLE_CHANGE',
    'TPTR_FLAG_WRONG_DOCUMENTS',
    'TPTR_FLAG_SUGGEST_PAYER_CHANGE',
    'TPTR_FLAG_PATIENT_NOT_READY_FOR_DISCHARGE',
    'TPTR_FLAG_ORDER_MISSING_INFORMATION',
    'TPTR_FLAG_WAITING_FEE_REQUESTED',
    'TPTR_FLAG_TRIP_DELEGATED',
  ],
}

export type PassengerIncidentResolutionType =
  | 'CONFIRMED'
  | 'NORIDE_HPTL_FAULT'
  | 'NORIDE_TPTR_FAULT'
  | 'NORIDE_ABLR_FAULT'
  | 'NORIDE_PTNT_FAULT'

export const ErrorCodeType: Record<KnownErrorCode, string> = {
  USER_MFU_ACL_ALREADY_MEMBER: 'USER_MFU_ACL_ALREADY_MEMBER',
  USER_MT_ACL_ALREADY_MEMBER: 'USER_MT_ACL_ALREADY_MEMBER',
  ASSOCIATION_ALREADY_EXISTS: 'ASSOCIATION_ALREADY_EXISTS',
  BR_ALREADY_SENT: 'BR_ALREADY_SENT',
  BR_ALREADY_EXISTS: 'BR_ALREADY_EXISTS',
  MISSING_MF_INVOICING_EMAIL_ADDRESS: 'MISSING_MF_INVOICING_EMAIL_ADDRESS',
  MISSING_MT_INVOICING_EMAIL_ADDRESS: 'MISSING_MT_INVOICING_EMAIL_ADDRESS',
  MISSION_UNTIMEOUT_PASSENGER_CONFLICT: 'MISSION_UNTIMEOUT_PASSENGER_CONFLICT',
  MISSION_UNTIMEOUT_PRESCRIPTION_CONFLICT: 'MISSION_UNTIMEOUT_PRESCRIPTION_CONFLICT',
  MF_FINESS_ALREADY_USED: 'MF_FINESS_ALREADY_USED',
  MF_MT_ALREADY_AGGREGATED: 'MF_MT_ALREADY_AGGREGATED',
  INCOMPATIBLE_BR_STATUS: 'INCOMPATIBLE_BR_STATUS',
  RESERVE_MT_ALREADY_IN_HPTL_BUNDLE_FOR_MF: 'RESERVE_MT_ALREADY_IN_HPTL_BUNDLE_FOR_MF',
  MT_HAS_RESERVE_AGREEMENT: 'MT_HAS_RESERVE_AGREEMENT',
  MT_TYPE_UPDATE_ERROR: 'MT_TYPE_UPDATE_ERROR',
  RESERVE_MF_IS_A4HS: 'RESERVE_MF_IS_A4HS',
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
  INVALID_BO_USER_EMAIL: 'INVALID_BO_USER_EMAIL',
}

export const MissionStatusGroup: Record<'confirmable' | 'dispatchable' | 'finished', MissionStatus[]> = {
  confirmable: ['CREATED', 'EDITED'],
  dispatchable: ['WAIT_DISPATCH_STATUS', ...dispatchStatuses],
  finished: [
    'ABORTED_BOUNCE',
    'ABORTED_EDIT',
    'ABORTED_MERGED',
    'EDITED_IMPROVED',
    'ABORTED_NOT_IN_ZONE',
    'DROPPED',
    'REPLACED',
    'TC_DISPATCH_TIMEOUT',
    'TC_TPTR_NOT_FOUND',
    'HPTL_TPTR_NOT_FOUND',
    'TC_HPTL_TPTR_NOT_FOUND',
    'HPTL_DISPATCH_TIMEOUT',
  ],
}

export const PassengerStatusGroup: Record<
  'edited' | 'confirmable' | 'dispatchable' | 'runnable' | 'finished' | 'scheduled',
  PassengerStatus[]
> = {
  edited: ['REQUESTED', 'CHANGED'],
  confirmable: ['CREATED'],
  scheduled: ['DELAYED'],
  dispatchable: ['DISPATCHING'],
  runnable: [
    'PREBOOKED',
    'BOOKED',
    'RUNNING',
    'ARRIVED',
    'WAIT_TPTR_CONFIRMATION',
    'WAIT_TC_CONFIRMATION',
    'WAIT_HPTL_CONFIRMATION',
  ],
  finished: [
    'CONFIRMED', // success colors
    'SUPPORT', // warning colors
    'ABORTED',
    'ABORTED_MERGED',
    'EDITED_IMPROVED',
    'PTNT_DROP',
    'HPTL_DROP',
    'PTNT_CANCEL',
    'HPTL_CANCEL',
    'TC_DISPATCH_TIMEOUT',
    'TC_TPTR_NOT_FOUND',
    'HPTL_TPTR_NOT_FOUND',
    'TC_HPTL_TPTR_NOT_FOUND',
    'HPTL_DISPATCH_TIMEOUT',
    'TC_PTNT_DROP',
    'TC_HPTL_DROP',
    'TC_PTNT_CANCEL',
    'TC_HPTL_CANCEL',
    'HPTL_EDIT_DROP',
    'HPTL_EDIT_CANCEL',
    'UNBOOKED',
    'UNPREBOOKED',
    'PTNT_NORIDE_HPTL_FAULT',
    'PTNT_NORIDE_PTNT_FAULT',
    'PTNT_NORIDE_TPTR_FAULT',
    'PTNT_NORIDE_AMBLER_FAULT',
    'HPTL_NORIDE_HPTL_FAULT',
    'HPTL_NORIDE_PTNT_FAULT',
    'HPTL_NORIDE_TPTR_FAULT',
    'HPTL_NORIDE_AMBLER_FAULT',
    'TPTR_NORIDE_HPTL_FAULT',
    'TPTR_NORIDE_PTNT_FAULT',
    'TPTR_NORIDE_TPTR_FAULT',
    'TPTR_NORIDE_AMBLER_FAULT',
  ],
}

export const ZIndexes = {
  NORMAL: 0,
  ABSOLUTE: 50,
  FIXED: 100,
  MODALS: 150,
  ERRORS: 300,
}

export enum FILTER_MISSION_TYPE {
  DISPATCH = 'dispatch',
  PRESCRIPTION = 'prescription',
  EVENTS = 'events',
}

export const FILTER_MFU_TYPE = {
  DETAILS: 'details',
  DISPATCH: 'dispatch',
  CUSTOMIZATION_TUNNEL: 'customization',
  CUSTOMIZATION_OPTIONS: 'options',
}

export const FILTER_MF_TYPE = {
  DETAILS: 'details',
  BUNDLES: 'bundles',
  CONTRACTS: 'contracts',
  INVOICES: 'invoices',
}

export const FILTER_MT_TYPE = {
  DETAILS: 'details',
  BUNDLES: 'bundles',
  CONTRACTS: 'contracts',
  REQUESTS: 'requests',
  INVOICES: 'invoices',
  CONSORTIUMS: 'consortiums',
}

export const FILTER_A4D_MT_TYPE = {
  DETAILS: 'details',
  REQUESTS: 'requests',
}

export const FILTER_INVOICE_TYPE = {
  DETAILS: 'details',
  HISTORY: 'history',
  THIRD_PARTY: '3p',
  NOT_THIRD_PARTY: 'n3p',
  AMBLER: 'ambler',
}

// Errors:
export type KnownErrorCode =
  | 'USER_MFU_ACL_ALREADY_MEMBER'
  | 'USER_MT_ACL_ALREADY_MEMBER'
  | 'BR_ALREADY_SENT'
  | 'BR_ALREADY_EXISTS'
  | 'ASSOCIATION_ALREADY_EXISTS'
  | 'MISSING_MF_INVOICING_EMAIL_ADDRESS'
  | 'MISSING_MT_INVOICING_EMAIL_ADDRESS'
  | 'MISSION_UNTIMEOUT_PASSENGER_CONFLICT'
  | 'MISSION_UNTIMEOUT_PRESCRIPTION_CONFLICT'
  | 'MF_FINESS_ALREADY_USED'
  | 'MF_MT_ALREADY_AGGREGATED'
  | 'INCOMPATIBLE_BR_STATUS'
  | 'RESERVE_MT_ALREADY_IN_HPTL_BUNDLE_FOR_MF'
  | 'MT_HAS_RESERVE_AGREEMENT'
  | 'MISSING_MT_INVOICING_EMAIL_ADDRESS'
  | 'MT_TYPE_UPDATE_ERROR'
  | 'RESERVE_MF_IS_A4HS'
  | 'USER_ALREADY_EXISTS'
  | 'INVALID_BO_USER_EMAIL'

export type UserAlreadyMemberErrorCode = SubType<
  KnownErrorCode,
  'USER_MT_ACL_ALREADY_MEMBER' | 'USER_MFU_ACL_ALREADY_MEMBER'
>

export const phoneRavenStatusWording: Record<PhoneRavenStatus, string> = {
  ANSWERED: 'Contacté',
  UNANSWERED: 'Injoignable',
  UNKNOWN: 'Inconnu',
}
